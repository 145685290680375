<template>
  <div class="ec-app-basic-information">
    
    <ec-row>
      <!-- App logo component (1/4) -->
      <ec-app-basic-information-logo/>
      
      <!-- App name and description component (2/4) -->
      <ec-app-basic-information-name-description/>
      
      <!-- App categories component (3/4) -->
      <ec-app-basic-information-categories/>
      
      <!-- App industries component (4/4)-->
      <ec-app-basic-information-industries/>
    </ec-row>
    
    <!-- App CTA component (1/1) -->
    <ec-row v-if="$vuetify.breakpoint.smAndUp">
      <ec-col cols="12" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <ec-alert type="gradient" class="ec-mt-3 ec-pa-3">
          <ec-app-basic-information-buttons/>
        </ec-alert>
      </ec-col>
    </ec-row>
    
    <ec-row v-else class="ec-ma-0 ec-pa-0">
      <ec-col cols="12" class="align-center justify-center">
        <ec-app-basic-information-buttons :full="true"/>
      </ec-col>
    </ec-row>
  
  </div>
</template>

<script>

import EcAppBasicInformationLogo from './EcAppBasicInformationLogo.vue'
import EcAppBasicInformationNameDescription from '@/pages/detail/components/EcAppBasicInformationNameDescription.vue'
import EcAppBasicInformationCategories from '@/pages/detail/components/EcAppBasicInformationCategories.vue'
import EcAppBasicInformationIndustries from '@/pages/detail/components/EcAppBasicInformationIndustries.vue'
import EcAppBasicInformationButtons from "@/pages/detail/components/EcAppBasicInformationButtons.vue";

export default {
  
  components: {EcAppBasicInformationButtons, EcAppBasicInformationIndustries, EcAppBasicInformationCategories, EcAppBasicInformationNameDescription, EcAppBasicInformationLogo}
  
}

</script>

<style lang="scss">

.ec-text-smaller {
  color: white !important;
  text-decoration: none !important;
}

</style>
