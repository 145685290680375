<template>
  <div :class="full ? 'd-flex justify-center align-center' : ''">
    
    <div :class="full ? 'd-flex flex-column justify-center align-center' : ''"
         v-if="$store.getters.getAppDetailField($route.params.id, 'type') === 'marketplace'">
      <ec-btn prepend-icon="icon-ec-rocket" color="primary" exact @click="internalLink()">
        {{ $store.getters.getTranslation('app_detail_ec_marketplace_button_text') }}
      </ec-btn>
      <a v-if="!full" class="ec-ml-2 ec-text-smaller"
         :href="'https://effectconnect.com/' + $store.getters.languageGetter"
         target="_blank">{{ $store.getters.getTranslation('app_detail_ec_no_client_yet_button_text') }}</a>
      <a class="ec-mt-2" v-else :href="'https://effectconnect.com/' + $store.getters.languageGetter"
         target="_blank">{{ $store.getters.getTranslation('app_detail_ec_no_client_yet_button_text') }}</a>
    </div>
    
    <div :class="full ? 'd-flex flex-column justify-center align-center' : ''"
         v-else-if="$store.getters.getAppDetailField($route.params.id, 'type') === 'module'">
      <ec-btn prepend-icon="icon-ec-rocket" color="primary" exact @click="internalLink()">
        {{ $store.getters.getTranslation('app_detail_ec_module_button_text') }}
      </ec-btn>
      <ec-app-basic-information-buttons-no-customer-link :full="full"/>
    </div>
    
    <div :class="full ? 'd-flex flex-column justify-center align-center' : ''"
         v-else-if="$store.getters.getAppDetailField($route.params.id, 'type') === 'api_v2_key' || $store.getters.getAppDetailField($route.params.id, 'type') === 'api_v3_key'">
      <ec-card-actions>
        <ec-btn prepend-icon="icon-ec-rocket" color="primary" exact @click="internalLink()">
          {{ $store.getters.getTranslation('app_detail_ec_integration_button_text') }}
        </ec-btn>
        <ec-btn prepend-icon="icon-ec-link-external" color="primary" outlined exact @click="remoteLink">
          {{ $store.getters.getAppDetailField($route.params.id, 'ctaButtonText') }}
        </ec-btn>
        <ec-app-basic-information-buttons-no-customer-link :full="full"/>
      </ec-card-actions>
    </div>
    
    <div :class="full ? 'd-flex justify-center align-center' : ''" v-else>
      <ec-btn prepend-icon="icon-ec-rocket" color="primary" exact @click="remoteLink">
        {{ $store.getters.getAppDetailField($route.params.id, 'ctaButtonText') }}
      </ec-btn>
    </div>
  
  </div>
</template>

<script>
import EcAppBasicInformationButtonsNoCustomerLink from '@/pages/detail/components/EcAppBasicInformationButtonsNoCustomerLink.vue'

export default {
  
  components: {EcAppBasicInformationButtonsNoCustomerLink},
  
  props: {
    full: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  
  methods: {
    internalLink() {
      window.open('https://go.effectconnect.com/app-store/apps/' + this.$route.params.id)
    },
    remoteLink() {
      let type = this.$store.getters.getAppDetailField(this.$route.params.id, 'ctaType')
      let link = this.$store.getters.getAppDetailField(this.$route.params.id, 'ctaButtonLink')
      if (type === 'external_link') {
        window.open(link)
      } else if (type === 'mail_to') {
        window.location.href = 'mailto:' + link
      }
    }
  }
  
}

</script>

<style lang="scss">

.ec-text-smaller {
  color: white !important;
  text-decoration: none !important;
}

</style>
