import Vue from 'vue'
import Vuex from 'vuex'
import {AppModel} from '@/models/AppModel'
import {GroupModel} from '@/models/GroupModel'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        language: '',
        languages: [
            {text: 'Nederlands', value: 'nl'},
            {text: 'English', value: 'en'},
            {text: 'Deutsch', value: 'de'}
        ],
        translations: null,
        data: null,
        translatedGroupsData: [],
        translatedSlidesData: [],
        translatedAppsData:   [],
        searchField: '',
        filters: [
            {countryFilter: null},
            {categoryFilter: null},
            {industryFilter: null}
        ],
        appliedFilters: []
    },

    mutations: {
        changeLanguage (state, language) {
            state.language = language
            state.translatedGroupsData = state.data['groups'][language]
            state.translatedSlidesData = state.data['slides'][language]
            state.translatedAppsData = state.data['apps'][language]
        },
        search(state, searchQuery) {
            state.searchField = searchQuery
        },
        filter(state, filterValues) {
            state.filters = filterValues
        },
        setFilter(state, {name, value}) {
            let index = state.filters.indexOf(state.filters.find(filter => Object.keys(filter)[0] === name))
            state.filters[index][name] = value
        },
        setAppliedFilterValues(state) {
            // Deep copy of filters, to break reactivity to allow user to filter only on 'apply'
            let appliedFilters = []
            JSON.parse(JSON.stringify(state.filters)).forEach(filter => {
                if (Object.values(filter)[0] !== null) {
                    appliedFilters.push(filter)
                }
            })
            state.appliedFilters = appliedFilters
        },
        translations(state, translations) {
            state.translations = translations
        },
        data(state, data) {
            state.data = data
        },
        translatedGroupsData(state, translatedGroupsData) {
            state.translatedGroupsData = translatedGroupsData
        },
        translatedSlidesData(state, translatedSlidesData) {
            state.translatedSlidesData = translatedSlidesData
        },
        translatedAppsData(state, translatedAppsData) {
            state.translatedAppsData = translatedAppsData
        }
    },

    actions: {
        getTranslations({commit, state}) {
            let promise = null
            promise = new Promise((resolve, reject) => {
                if (state.data) {
                    resolve()
                } else {
                    fetch('/json/translations.json')
                    .then(response => response.json())
                    .then((translations) => {
                        commit('translations', translations)
                        resolve()
                    }).catch(() => {
                        reject()
                    })
                }
            })
            return promise
        },
        getData({commit, state}) {
            let promise = null
            promise = new Promise((resolve, reject) => {
                if (state.data) {
                    resolve()
                } else {
                    fetch('/json/data.json')
                    .then(response => response.json())
                    .then((data) => {
                        commit('data', data)
                        commit('translatedGroupsData', data['groups']['en'])
                        commit('translatedSlidesData', data['slides']['en'])
                        commit('translatedAppsData', data['apps']['en'])
                        resolve()
                    }).catch(() => {
                        reject()
                    })
                }
            })
            return promise
        }
    },

    getters: {
        languageGetter(state) {
            return state.language ?? 'en'
        },
        languagesGetter(state) {
            return state.languages
        },
        translationsGetter(state) {
            return state.translations
        },
        translationWithParametersGetter: (state) => (translationString, language, parameters = []) => {
            let translation = state.translations[translationString][language].toString()
            parameters.forEach(parameter => {
                 translation = translation.replace('{' + parameter.text + '}', parameter.value)
            })
            return translation
        },
        translatedGroupsData(state) {
            return state.translatedGroupsData
        },
        translatedSlidesData(state) {
            return state.translatedSlidesData
        },
        translatedAppsData(state) {
            return state.translatedAppsData
        },
        getTranslation: (state) => (translationString, language = 'en') => {
            if (!state.language) {
                return state.translations[String(translationString)][language] // On first load, state if undefined
            } else {
                return state.translations[String(translationString)][state.language]
            }
        },
        getAppDetailField: (state) => (appId, field) => {
            let app = new AppModel(state.translatedAppsData.data.find(app => app.id === parseInt(appId)))
            return app[field]
        },
        getGroupDetailField: (state) => (groupId, field) => {
            let group = new GroupModel(state.translatedGroupsData.data.find(group => group.id === parseInt(groupId)))
            if (!field) {
                return group
            } else {
                return group[field]
            }
        },
        searchFieldGetter(state) {
            return state.searchField
        },
        filtersGetter(state) {
            return state.filters
        },
        countryFilterGetter(state) {
            let index = state.filters.indexOf(state.filters.find(filter => Object.keys(filter)[0] === 'countryFilter'))
            return state.filters[index]['countryFilter']
        },
        categoryFilterGetter(state) {
            let index = state.filters.indexOf(state.filters.find(filter => Object.keys(filter)[0] === 'categoryFilter'))
            return state.filters[index]['categoryFilter']
        },
        industryFilterGetter(state) {
            let index = state.filters.indexOf(state.filters.find(filter => Object.keys(filter)[0] === 'industryFilter'))
            return state.filters[index]['industryFilter']
        },
        appliedFiltersGetter(state) {
            return state.appliedFilters
        },
        getAltText: () => (name) => {
            return 'image–' + name.toLowerCase().replace(/\s/g,'–').replace(',', '') + '–effectconnect–app–store'
        }
    }
})

export default store
